import React from 'react';

import Layout from '../components/Layout';
// import Scroll from '../components/Scroll';

// import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';

const IndexPage = () => (
  <Layout>
    <Header />
    <section className="banner-section d-flex justify-content-center align-items-center flex-column">
      <h1>We are Jukin Brands</h1>
      <p>
        Every day we reach 1 in 5 US adults ages 18 to 34. Global digital-native
        brands, with a massive US footprint
      </p>
    </section>
    <section className="brands"></section>
    <SocialLinks siteLogoClass="hide" />
    <Footer />
  </Layout>
);

export default IndexPage;
